var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('window-header'),_c('nav-form'),_c('section',[_c('article',[_c('field',{staticStyle:{"top":"5px","left":"5px"},attrs:{"name":"codigo","widget":"input","searchable":"","label":"Código","placeholder":"Cód.","width":"50px","readonly":_vm.mode == 'edit',"inputStyle":"font-weight:bold;text-align:center;"}}),_c('field',{staticStyle:{"top":"5px","left":"65px"},attrs:{"name":"descripcion","widget":"input","searchable":"","label":"Tesorería","help":"Descripcion","placeholder":"Descripcion","width":"270px"}}),_c('fieldset',{staticClass:"box",staticStyle:{"right":"100px","top":"5px"}},[_c('legend',{staticStyle:{"font-weight":"bold"}},[_vm._v("Total Gr.")]),_vm._v(" "+_vm._s(_vm.sumTotalGr)+"gr. ")]),_c('fieldset',{staticClass:"box",staticStyle:{"right":"10px","top":"5px"}},[_c('legend',{staticStyle:{"font-weight":"bold"}},[_vm._v("Total €")]),_vm._v(" "+_vm._s(_vm.sumTotalEur)+"€ ")]),_c('field',{ref:"lineas",staticStyle:{"top":"55px","left":"10px"},attrs:{"name":"lineas","widget":"handsontable","height":275,"minRows":11,"columns":[
          {name:'fecha',header:'Fecha', type: 'date2'},
          {name:'concepto',header:'Concepto'},
          {name:'ubicacion',header:'Ubicación',type:'m2o', label:'descripcion', primary:'codigo'},
          {name:'debe_eur',header:'Debe(€)', type: 'numeric', numericFormat: { pattern: '0,0.00'}},
          {name:'haber_eur',header:'Haber(€)', type: 'numeric', numericFormat: { pattern: '0,0.00'}},
          {name:'debe_gr',header:'Debe(gr)', type: 'numeric', numericFormat: { pattern: '0,0.00'}},
          {name:'haber_gr',header:'Haber(gr)', type: 'numeric', numericFormat: { pattern: '0,0.00'}} ]}})],1)]),_c('v-collapse-wrapper',{ref:"main-collapse",attrs:{"active":true}},[_c('div',{directives:[{name:"collapse-toggle",rawName:"v-collapse-toggle"}],staticClass:"header"},[_c('div',{staticClass:"item-title"},[_vm._v("Listado")]),_c('div',{staticClass:"item-after"},[_vm._v(_vm._s(_vm.count))])]),_c('div',{directives:[{name:"collapse-content",rawName:"v-collapse-content"}],staticClass:"my-content"},[_c('hot-table',{ref:"hotTableComponent",attrs:{"settings":_vm.htSettings}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }