<template>
  <div>
    <window-header></window-header>
    <nav-form></nav-form>
    <section>
      <article>
        <field
          name="codigo"
          widget="input"
          searchable
          label="Código"
          placeholder="Cód."
          width="50px"
          style="top:5px; left:5px;"
          :readonly="mode == 'edit'"
          inputStyle="font-weight:bold;text-align:center;"
        />
        <field
          name="descripcion"
          widget="input"
          searchable
          label="Tesorería"
          help="Descripcion"
          placeholder="Descripcion"
          width="270px"
          style="top:5px; left:65px;"
        />

        <fieldset class="box" style="right: 100px; top: 5px;">
          <legend style="font-weight:bold;">Total Gr.</legend>
          {{sumTotalGr}}gr.
        </fieldset>
        <fieldset class="box" style="right: 10px; top: 5px;">
          <legend style="font-weight:bold;">Total €</legend>
          {{sumTotalEur}}€
        </fieldset>

        <field
          ref="lineas"
          name="lineas"
          widget="handsontable"
          style="top:55px; left:10px;"
          :height="275"
          :minRows="11"
          :columns="[
            {name:'fecha',header:'Fecha', type: 'date2'},
            {name:'concepto',header:'Concepto'},
            {name:'ubicacion',header:'Ubicación',type:'m2o', label:'descripcion', primary:'codigo'},
            {name:'debe_eur',header:'Debe(€)', type: 'numeric', numericFormat: { pattern: '0,0.00'}},
            {name:'haber_eur',header:'Haber(€)', type: 'numeric', numericFormat: { pattern: '0,0.00'}},
            {name:'debe_gr',header:'Debe(gr)', type: 'numeric', numericFormat: { pattern: '0,0.00'}},
            {name:'haber_gr',header:'Haber(gr)', type: 'numeric', numericFormat: { pattern: '0,0.00'}},
            //TODO: {name:'ubicacion',header:'Ubicación', widget: 'm2o'},
          ]"
        />
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{count}}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function() {
    return {
      title: "Tesoreria",
      dbAdapter: "tesoreria",
      primary: "codigo",
      sequence: { name: "tesoreria" }
    };
  },
  computed: {
    sumDebe() {
      return (this.formData.lineas || [])
        .map(el => parseFloat(el.debe_eur) || 0)
        .reduce((total, el) => total + el, 0)
        .toFixed(2);
    },
    sumHaber() {
      return (this.formData.lineas || [])
        .map(el => parseFloat(el.haber_eur) || 0)
        .reduce((total, el) => total + el, 0)
        .toFixed(2);
    },
    sumTotalEur() {
      return (this.sumDebe - this.sumHaber).toFixed(2);
    },
    sumDebeGr() {
      return (this.formData.lineas || [])
        .map(el => parseFloat(el.debe_gr) || 0)
        .reduce((total, el) => total + el, 0)
        .toFixed(2);
    },
    sumHaberGr() {
      return (this.formData.lineas || [])
        .map(el => parseFloat(el.haber_gr) || 0)
        .reduce((total, el) => total + el, 0)
        .toFixed(2);
    },
    sumTotalGr() {
      return (this.sumDebeGr - this.sumHaberGr).toFixed(2);
    }
  },
  mounted() {
    var self = this;
    self.$refs.lineas.field.hotInstance.addHook("beforeChange", function(
      changes,
      source
    ) {
      if (source == "loadData" || !changes || !changes.length) return;
      let h = this;
      changes.forEach(function(change, index) {
        var row = change[0];
        var prop = change[1];
        var oldVal = change[2];
        var newVal = change[3];
        if (!h.getSourceData()[row]["debe_eur"])
          changes.push([row, "debe_eur", null, 0]);
        if (!h.getSourceData()[row]["haber_eur"])
          changes.push([row, "haber_eur", null, 0]);
        if (!h.getSourceData()[row]["debe_gr"])
          changes.push([row, "debe_gr", null, 0]);
        if (!h.getSourceData()[row]["haber_gr"])
          changes.push([row, "haber_gr", null, 0]);
      });
    });
  }
};
</script>
<style>
.box {
  position: absolute;
  text-align: center;
  width: 80px;
  height: 40px;
  padding: 3px;
}
.box legend {
  font-size: 0.8em;
}
</style>


